import React from "react";
import PropTypes from "prop-types";

import {OrderObject} from "./../Queries/OrderQuery";
import {
  showAddProducts,
  showRemoveProducts,
  isNonErapHomeMods
} from "./../BusRules/access";
import AddItems from "./AddItems/index";
import RemoveItems from "./RemoveItems/index";
import AssignToPreferedPartner from "./Assign/ToPreferedPartner";
import AssignToAlternativePartner from "./Assign/ToAlternativePartner";
import AssignCancel from "./AssignCancel/index";
import SendToPartner from "./SendToPartner/index";
import SelectSalesRep from "./Assign/SelectSalesRep";
import SelectDebtor from "./Assign/SelectDebtor";
import MarkAllClientEligibleYes from "./MarkAllClientEligibleYes";
import SplitLinesToNewOrder from "./SplitLinesToNewOrder/index";
import CheckStock from "./CheckStock";
import PreferredPartnerBtn from "../PreferredPartnerBtn";

class FooterButtons extends React.Component {
  render() {
    const {
      order,
      saveLine,
      selections,
      clearSelections,
      selectedRows,
      currentUser,
      isSuborderDisplay,
      uiActive,
      disableUIFunc
    } = this.props;

    const showPreferredPartnerAssign =
      order.uiState && order.uiState.stageAssignment;
    const showAssign = order.uiState && order.uiState.showAssignment;
    const showUnassign = order.uiState && order.uiState.stageAssignAsPartner;
    const supplyPartner = order.partner;
    const installPartner = order.installPartner;

    return (
      <React.Fragment>
        {!showUnassign && !isSuborderDisplay && (
          <React.Fragment>
            <div className="order-lines-footer-line">
              {showAddProducts(currentUser, order) && (
                <AddItems
                  order={order}
                  uiActive={uiActive}
                  disableUIFunc={disableUIFunc}
                  currentUser={currentUser}
                />
              )}
              {showRemoveProducts(currentUser, order) && (
                <RemoveItems
                  order={order}
                  selections={selections}
                  saveLine={saveLine}
                  clearSelections={clearSelections}
                  uiActive={uiActive}
                />
              )}
              {order.uiState.showCheckStockButton && (
                <CheckStock order={order} />
              )}
              {order.uiState.showCheckFreightButton && (
                <a
                  href="https://crm.aidacare.com.au/CRM/FreightGuide"
                  className="button grey"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check Freight Price
                </a>
              )}
              {order.uiState.showPreferredPartnerBtn && (
                <PreferredPartnerBtn
                  order={order}
                  currentUser={currentUser}
                />
              )}
            </div>
            {order.uiState.showStep1 && order.uiState.showSplitOrderBtn && (
              <div className="order-lines-footer-line">
                <SplitLinesToNewOrder
                  order={order}
                  selections={selections}
                  repairLinesSelected={this.repairLinesSelected()}
                  homeModLinesSelected={this.homeModLinesSelected()}
                  selectedRows={selectedRows}
                  clearSelections={clearSelections}
                />
                <MarkAllClientEligibleYes saveLine={saveLine} order={order} />
              </div>
            )}
          </React.Fragment>
        )}
        {showAssign && (
          <div className="order-lines-footer-line">
            {showPreferredPartnerAssign && !isNonErapHomeMods(order) && (
              <div className="partner-button">
                <AssignToPreferedPartner
                  order={order}
                  partner={supplyPartner}
                  partnerType="supply"
                  selections={selections}
                  repairLinesSelected={this.repairLinesSelected()}
                  homeModLinesSelected={this.homeModLinesSelected()}
                  multipleQtyHireSelected={this.multipleQtyHireSelected()}
                  trialLinesSelected={this.trialLinesSelected()}
                  clearSelections={clearSelections}
                  uiActive={uiActive}
                  disableUIFunc={disableUIFunc}
                  buttonText={
                    "Assign to preferred partner " +
                    ((supplyPartner && supplyPartner.name) || "")
                  }
                />
              </div>
            )}
            {showPreferredPartnerAssign && isNonErapHomeMods(order) && (
              <React.Fragment>
                <div className="partner-button">
                  <label>{(supplyPartner && supplyPartner.name) || ""}</label>
                  <AssignToPreferedPartner
                    order={order}
                    partner={supplyPartner}
                    partnerType="supply"
                    selections={selections}
                    repairLinesSelected={this.repairLinesSelected()}
                    homeModLinesSelected={this.homeModLinesSelected()}
                    multipleQtyHireSelected={this.multipleQtyHireSelected()}
                    trialLinesSelected={this.trialLinesSelected()}
                    clearSelections={clearSelections}
                    uiActive={uiActive}
                    disableUIFunc={disableUIFunc}
                    buttonText={"Assign to supplier"}
                  />
                </div>
                <div className="partner-button">
                  <label>{(installPartner && installPartner.name) || ""}</label>
                  <AssignToPreferedPartner
                    order={order}
                    partner={installPartner}
                    partnerType="install"
                    selections={selections}
                    repairLinesSelected={this.repairLinesSelected()}
                    homeModLinesSelected={this.homeModLinesSelected()}
                    multipleQtyHireSelected={this.multipleQtyHireSelected()}
                    trialLinesSelected={this.trialLinesSelected()}
                    clearSelections={clearSelections}
                    uiActive={uiActive}
                    disableUIFunc={disableUIFunc}
                    buttonText={"Assign to installer"}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="partner-button">
              <AssignToAlternativePartner
                order={order}
                selections={selections}
                repairLinesSelected={this.repairLinesSelected()}
                homeModLinesSelected={this.homeModLinesSelected()}
                multipleQtyHireSelected={this.multipleQtyHireSelected()}
                trialLinesSelected={this.trialLinesSelected()}
                key={selections}
                selectedRows={selectedRows}
                clearSelections={clearSelections}
                uiActive={uiActive}
                disableUIFunc={disableUIFunc}
              />
            </div>
            {this.multipleQtyHireSelected() && (
              <div className="note">
                <em>* Hire/RTO Items must be quantity = 1</em>
              </div>
            )}
          </div>
        )}
        {showUnassign && (
          <div>
            {!order.uiState.stageCanSendToPartner && (
              <div className="message message--error">
                <p>
                  You must assign all <strong>Unassigned Items</strong> before
                  you can send to Partner
                </p>
              </div>
            )}
            <div className="order-lines-footer-line">
              <AddItems
                order={order}
                isAddFreight={true}
                uiActive={uiActive}
                disableUIFunc={disableUIFunc}
                currentUser={currentUser}
              />
              {order.uiState.hasSalesRep && (
                <SelectSalesRep order={order} currentUser={currentUser} />
              )}
              {order.uiState.showDebtor && (
                <SelectDebtor order={order} currentUser={currentUser} />
              )}
              <SendToPartner order={order} uiActive={uiActive} />
              {order.uiState.showCheckStockButton &&
                order.partner.isInternal && <CheckStock order={order} />}
              <AssignCancel order={order} />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
  repairLinesSelected = () => {
    return this.props.selectedRows.filter(ol => ol.isRepair).length > 0;
  };
  homeModLinesSelected = () => {
    return this.props.selectedRows.filter(ol => ol.isHomeMod).length > 0;
  };
  multipleQtyHireSelected = () => {
    return (
      this.props.selectedRows.filter(
        ol => ol.quantity > 1 && ol.saleType !== "BUY"
      ).length > 0
    );
  };
  trialLinesSelected = () => {
    return this.props.selectedRows.some(ol => ol.trialStatus !== "N/A");
  };
}

FooterButtons.propTypes = {
  order: OrderObject.isRequired,
  saveLine: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired,
  clearSelections: PropTypes.func.isRequired,
  selectedRows: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  isSuborderDisplay: PropTypes.bool.isRequired,
  uiActive: PropTypes.bool.isRequired,
  disableUIFunc: PropTypes.func.isRequired
};

export default FooterButtons;
