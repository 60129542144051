import React from "react";
import PropTypes from "prop-types";
import StreamlinedAssigner from "./StreamlinedAssigner";

class StreamlinedAssignmentBtn extends React.Component {
  render() {
    const { order } = this.props;

    return (
      <StreamlinedAssigner
        order={order}
        render={({ sendToPartner }) => (
          <button
            className="button primary"
            onClick={() => {
              if (window.confirm("Send to partner?")) {
                sendToPartner();
              }
            }}
            disabled={!order.uiState.allowStreamlinedAssignment}
          >
            Send to Partner ({order.partner ? order.partner.name : ""})
          </button>
        )}
      />
    );
  }
}

StreamlinedAssignmentBtn.propTypes = {
  order: PropTypes.object
};

export default StreamlinedAssignmentBtn;
