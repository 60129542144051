import React from "react";
import PropTypes from "prop-types";
import { Mutation } from "@apollo/client/react/components";
import { gql } from "@apollo/client";

class RemoveFromContractBtn extends React.Component {
  state = {
    disabled: false
  };

  render() {
    return (
      <Mutation
        mutation={REMOVE_FROM_CONTRACT}
        onError={this.onError}
        onCompleted={this.onCompleted}
      >
        {update => (
          <button
            onClick={e => this.onClick(update)}
            className="button button--small red"
            disabled={this.state.disabled}
          >
            Remove from contract
          </button>
        )}
      </Mutation>
    );
  }

  onClick = update => {
    const { contractId, userId } = this.props;
    if (
      window.confirm(
        "This will remove the user from this contract, are you sure?"
      )
    ) {
      this.setState({ disabled: true });
      update({
        variables: {
          id: contractId,
          userId: userId
        }
      });
    }
  };

  onError = ({ graphQLErrors, networkError }) => {
    console.log("onError occurred");
    console.log(graphQLErrors);
    console.log(networkError);
  };

  onCompleted = data => {
    this.props.params.api.refreshServerSide();
  };
}

RemoveFromContractBtn.propTypes = {
  contractId: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired
};

export default RemoveFromContractBtn;

const REMOVE_FROM_CONTRACT = gql`
  mutation removeUserFromContract($id: ID!, $userId: ID!) {
    removeUserFromContract(id: $id, userId: $userId) {
      userRemoved
    }
  }
`; 